import React from 'react'
import { TextInput, Filter, Datagrid, EmailField, List, TextField } from 'ra-ui-materialui'
import ChipArrayField from '../../components/ChipArrayField'
import { userRoles} from '../../constants'

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label='Filtrer par email' source='email' alwaysOn/>
    <TextInput label='Filtrer par prénom' source='firstName' alwaysOn/>
    <TextInput label='Filtrer par nom' source='lastName' alwaysOn/>
    <TextInput label='Filtrer par téléphone' source='phoneNumber' alwaysOn/>
  </Filter>
)

export const UserList = props => (
  <List filters={<UserFilter/>} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id"/>
      <TextField source="firstName"/>
      <TextField source="lastName"/>
      <EmailField source="email"/>
      <TextField source="username" />
      <ChipArrayField source="roles" choices={userRoles}/>
    </Datagrid>
  </List>
)
