export const resourcesFrMessages = {
  resources: {
    users: {
      name: 'Utilisateur |||| Utilisateurs',
      fields: {
        id: 'ID',
        birthdate: 'Date de naissance',
        email: 'E-mail',
        enabled: 'Actif',
        gender: 'Sexe',
        username: 'Pseudo',
        onBoarded: 'Intégré',
        phoneNumber: 'Téléphone',
        firstName: 'Prénom',
        lastName: 'Nom',
        roles: 'Rôles',
        isAdmin: 'Administrateur',
        occupation: 'Poste',
        createdOn: 'Date de création',
        thumb: 'Lien image'
      }
    },
    candidates: {
      name: 'Candidat |||| Candidats',
      fields: {
        id: 'ID',
        firstName: "Prénom",
        lastName: "Nom",
        phoneNumber: "Téléphone mobile",
        email: "Adresse e-mail",
        department: "Dept",
        locality: "Ville",
        company: "Entreprise",
        officePhoneNumber: "Téléphone société",
        avoidCall: "Ne pas contacter",
        isLooking: "A l'écoute",
        lookingReason: "A l'écoute pour",
        calledOn: "Dernier échange",
        emailSent: "Mail envoyé",
        contacted: "Déjà Contacté",
        emailSentOn: "Date d'envoi du mail",
        resumeReceived: "Cv reçu",
        jobTypeId: "Métier",
        userId: "Crée par",
        occupation: "Fonction",
        createdOn: 'Date de création',
      }
    },
    jobTypes: {
      name: 'Métier |||| Métiers',
      fields: {
        id: 'ID',
        name: 'Pôle',
        description: 'Description du pôle',
        levelDepth: 'Niveau profondeur',
        position: 'Position',
        status: 'Statut',
        jobTypeId: 'Métier Parent',
        userId: 'Responsable Pôle',
        responsibleIds: 'Responsables Pôle',
        createdOn: 'Date de création',
        thumb: 'Lien image'
      }
    },
    comments: {
      name: 'Commentaire |||| Commentaires',
      fields: {
        id: 'ID',
        content: 'Message',
        thumb: 'Lien image'
      }
    }
  }
}
