import config from '../config'

function removeAuthData () {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
}

function getUserData () {
  const item = localStorage.getItem('user')
  return item ? JSON.parse(item) : null;
}

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request(`${config.API_ENDPOINT}/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password: password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(({ user, authentication }) => {
        localStorage.setItem('token', authentication.token)
        localStorage.setItem('user', JSON.stringify(user))
        return Promise.resolve()
      })
  },
  // called when the user clicks on the logout button
  logout: () => {
    const token = localStorage.getItem('token')
    if (!token) {
      return Promise.resolve()
    }

    const request = new Request(`${config.API_ENDPOINT}/logout`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
    return fetch(request)
      .finally(() => {
        removeAuthData()
        return Promise.resolve()
      })
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      removeAuthData()
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const token = localStorage.getItem('token')
    if (token && token.length) {
      return Promise.resolve()
    }
    return Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const userData = getUserData()
    if (!userData || !userData.roles) {
      return Promise.reject()
    }
    return Promise.resolve(userData.roles);
  },
}
