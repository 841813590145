import * as React from 'react'
import {
  FileInput,
  FileField,
  DateInput,
  ReferenceInput,
  TextInput,
  SaveButton,
  DeleteButton,
  Button,
  SelectInput,
  NumberInput,
  BooleanInput,
  Link,
  TextField,
  DateField,
  required,
  TabbedForm,
  FormTab
} from 'react-admin'
import { Card, CardContent, CardHeader, Avatar, Typography, Box, Toolbar } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'

const AddNewCommentButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/comments/create',
      search: `?candidateId=${record.id}`,
    }}
    label="Ajouter un commentaire"
  >
    <ChatBubbleIcon/>
  </Button>
)

const cardStyle = {
  margin: '0.5em',
  verticalAlign: 'top'
}

const CommentGrid = ({ comments }) => {
  if (!Array.isArray(comments) || !comments.length) {
    return null
  }
  return (
    <div style={{ margin: '1em' }}>
      {comments.map((comment, index) =>
        <Card key={comment.id} style={cardStyle}>
          <CardHeader
            title={<TextField record={comment} source="username"/>}
            subheader={<DateField record={comment} source="createdOn"/>}
            avatar={<Avatar icon={<PersonIcon/>}/>}
          />
          <CardContent>
            <TextField record={comment} source="content"/>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

const CandidateFile = (props) => {
  return (
    <>
      <Box p="1em">
        <Box display="flex">
          <Box flex={2} mr="1em">
            <Typography variant="h6" gutterBottom>Enseigne</Typography>
            <TextInput label="Entreprise" source="company" validate={required()} fullWidth/>
            <TextInput label="Fonction" source="occupation" fullWidth/>
            <ReferenceInput label="Métier" source="jobTypeId" reference="jobTypes" validate={required()}
                            fullWidth><SelectInput
              optionText="name"/></ReferenceInput>
            <TextInput label="Téléphone société" source="officePhoneNumber" validate={required()} fullWidth/>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <NumberInput label="Département" source="department" validate={required()} fullWidth/>
              </Box>
              <Box flex={2} ml="0.5em">
                <TextInput label="Ville" source="locality" validate={required()} fullWidth/>
              </Box>
            </Box>
            <Box mt="1em"/>

            <Typography variant="h6" gutterBottom>Identité</Typography>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput label="Prénom" source="firstName" fullWidth/>
              </Box>
              <Box flex={1} ml="0.5em">
                <TextInput label="Nom" source="lastName"/>
              </Box>
            </Box>
            <TextInput label="Adresse e-mail" source="email" type="email" fullWidth/>
            <TextInput label="Téléphone mobile" source="phoneNumber"/>
          </Box>

          <Box flex={1} ml="1em">
            <Typography variant="h6" gutterBottom>Recherche</Typography>
            <BooleanInput label="Ne pas contacter" source="avoidCall" fullWidth/>
            <BooleanInput label="Déjà Contacté" source="contacted" fullWidth/>
            <BooleanInput label="Cv reçu" source="resumeReceived" fullWidth/>
            <BooleanInput label="A l'écoute" source="isLooking" fullWidth/>
            <TextInput label="A l'écoute pour" source="lookingReason" multiline fullWidth/>
            <DateInput label="Dernier échange" source="calledOn" fullWidth/>
            <DateInput label="Date de rappel" source="callbackDate" fullWidth/>
            <BooleanInput label="Mail envoyé" source="emailSent" fullWidth/>
            <DateInput label="Date d'envoi du mail" source="emailSentOn" fullWidth/>
          </Box>

        </Box>
      </Box>
      {props.comments && (
        <Box p="1em"><Typography variant="h6" gutterBottom>Commentaires</Typography>{props.comments &&
        <CommentGrid comments={props.comments}/>}</Box>)
      }
    </>
  )
}

const CustomToolbar = (props) => {
  return (
    <Toolbar>
      <Box display="flex" justifyContent="space-between" width="100%">
        <SaveButton
          saving={props.saving}
          handleSubmitWithRedirect={props.handleSubmitWithRedirect}
        />
        {props.isEdit && <AddNewCommentButton record={props.record}/>}
        {props.isEdit && props.record && <DeleteButton
          record={props.record}
          basePath={props.basePath}
          resource={props.resource}
          undoable={props.undoable}
        />}
      </Box>
    </Toolbar>
  )
}

const Preview = (props) => {
  if (!props?.file) {
    return null
  }
  const docs = [{ uri: props.file }]

  return (<DocViewer pluginRenderers={DocViewerRenderers} documents={docs} style={{ minHeight: 500 }}/>)
}

const MemoizedPreview = React.memo(Preview)

export function CandidateEditForm (props) {
  return (
    <>
      <TabbedForm {...props} toolbar={<CustomToolbar {...props} />}>
        <FormTab label="Fiche Candidat">
          <CandidateFile {...props}/>
        </FormTab>

        {props.isEdit && (
          <FormTab label="CV">
            <FileInput source="resumeFile" label="">
              <FileField source="src" title="CV du candidat"/>
            </FileInput>
            <FileField source="resume" title="Télécharger le fichier" target="_blank" label={''}/>
            <MemoizedPreview file={props?.record?.resume}/>
          </FormTab>
        )}

        {props.isEdit && (
          <FormTab label="Synthèse">
            <FileInput source="synthesisFile" label="">
              <FileField source="src" title="Synthèse du candidat"/>
            </FileInput>
            <FileField source="synthesis" title="Télécharger le fichier" target="_blank" label={''}/>
            <MemoizedPreview file={props?.record?.synthesis}/>
          </FormTab>
        )}
      </TabbedForm>
    </>
  )
}
