import React from 'react'
import {
  Create
} from 'react-admin'
import { CandidateEditForm } from './CandidateEditForm'

export const CandidateCreate = props => (
  <Create {...props}>
    <CandidateEditForm redirect='list'/>
  </Create>
)
