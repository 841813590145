import React from 'react'
import { required, Create, SimpleForm, TextInput, SelectArrayInput, ReferenceArrayInput } from 'react-admin'

export const JobTypeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()}/>
      <TextInput source="description"/>
      <ReferenceArrayInput source="responsibleIds" reference="users" allowEmpty={false} validate={required()}  >
        <SelectArrayInput optionText="fullName"/>
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
)
