import React from 'react'
import { useState, useEffect } from 'react'
import {
  useDataProvider,
  Loading,
  Edit
} from 'react-admin'
import { CandidateEditForm } from './CandidateEditForm'

export const CandidateEdit = props => {
  const candidateId = props.id
  const dataProvider = useDataProvider()
  const [comments, setComments] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    dataProvider.getOne(`comments/candidates`, { id: candidateId })
      .then((response) => {
        const data = response.data?.comments
        setComments(data)
        setLoading(false)
      })
      .catch((err) => {
        setError(error)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <Loading/>
  }

  return (
    <Edit undoable={false} {...props}>
      <CandidateEditForm comments={comments} isEdit={true} {...props}/>
    </Edit>)
}
