import React from 'react'
import { required, Create, SimpleForm, TextInput, SelectInput } from 'react-admin'
import { userRoles } from '../../constants'

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstName" validate={required()}/>
      <TextInput source="lastName" validate={required()}/>
      <TextInput source="email" type="email" validate={required()}/>
      <TextInput source="username" validate={required()}/>
      <SelectInput label="Rôle" source="role" choices={userRoles} validate={required()}/>
      <TextInput label="Mot de passe" source="password" type="password" validate={required()}/>
      <TextInput label="Confirmation mot de passe" source="matchingPassword" type="password" validate={required()}/>
    </SimpleForm>
  </Create>
);
