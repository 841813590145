import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  required,
  SelectArrayInput, ReferenceArrayInput
} from 'react-admin'

export const JobTypeEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id"/>
      <DateInput disabled source="createdOn" />
      <TextInput source="name" validate={required()}/>
      <TextInput source="description"/>
      <ReferenceArrayInput source="responsibleIds" reference="users" allowEmpty={false} validate={required()}  >
        <SelectArrayInput optionText="fullName"/>
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
)
