import frenchMessages from 'ra-language-french'
import {fetchUtils} from 'ra-core'
import React from 'react'
import {Admin, Resource} from 'react-admin'
import config from './config'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import './App.css'
import {CandidateCreate} from './pages/candidates/CandidateCreate'
import {CandidateEdit} from './pages/candidates/CandidateEdit'
import {CandidateList} from './pages/candidates/CandidateList'
import {CommentCreate} from './pages/candidates/CommentCreate'
import {JobTypeCreate} from './pages/jobTypes/JobTypeCreate'
import {JobTypeEdit} from './pages/jobTypes/JobTypeEdit'
import {JobTypeList} from './pages/jobTypes/JobTypeList'
import {UserCreate} from './pages/users/UserCreate'
import {UserEdit} from './pages/users/UserEdit'
import {UserList} from './pages/users/UserList'
import authProvider from './providers/authProvider'
import dataProvider from './providers/dataProvider'
import * as domainMessages from './providers/i18n'
import {createMuiTheme} from "@material-ui/core";

const translations = {
    ...frenchMessages,
    ...domainMessages.resourcesFrMessages
}

const i18nProvider = polyglotI18nProvider(() => translations, 'fr')

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        let headers = new Headers()
        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')
        options.headers = headers
    }
    const token = localStorage.getItem('token')
    options.headers.set('Authorization', `Bearer ${token}`)
    return fetchUtils.fetchJson(url, options)
}

const isAdmin = (roles) => {
    if (!roles || !Array.isArray(roles)) {
        return false
    }
    return roles.indexOf('ROLE_ADMIN') !== -1;
}

const theme = createMuiTheme({
    palette:{
        type: 'light'
    },
    overrides:{
        MuiTableRow: {
            head: {
                backgroundColor: 'lightgray',
                "& > th ": {
                    color: 'black',
                    fontWeight: 'bold',
                }
            },
        }
    },
});

String.prototype.extension = function () {
    return this.split('.').pop()
}

const App = () => (
    <Admin theme={theme} i18nProvider={i18nProvider} authProvider={authProvider}
           dataProvider={dataProvider(config.API_ENDPOINT, httpClient)}>
        {permissions => [
            <Resource
                name="users"
                list={isAdmin(permissions) ? UserList : null}
                edit={isAdmin(permissions) ? UserEdit : null}
                create={isAdmin(permissions) ? UserCreate : null}
            />,
            <Resource name="jobTypes" list={JobTypeList} edit={JobTypeEdit} create={JobTypeCreate}/>,
            <Resource name="candidates" list={CandidateList} edit={CandidateEdit} create={CandidateCreate}/>,
            <Resource name="comments" create={CommentCreate}/>
        ]}
    </Admin>
)

export default App
