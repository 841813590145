import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    ChipField,
    Filter,
    TextInput,
    ReferenceArrayField,
    SingleFieldList
} from 'react-admin'


const JobTypeFilter = props => (
    <Filter {...props}>
        <TextInput label='Filtrer par nom' source='name' alwaysOn/>
        <TextInput label='Filtrer par description' source='description' alwaysOn/>
    </Filter>
)

export const JobTypeList = props => {
    return (
        <List filters={<JobTypeFilter/>} {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id"/>
                <TextField source="name"/>
                <ReferenceArrayField source="responsibleIds" reference="users">
                    <SingleFieldList>
                        <ChipField source="fullName"/>
                    </SingleFieldList>
                </ReferenceArrayField>
            </Datagrid>
        </List>
    )
}

