import { stringify } from 'query-string'
import { fetchUtils } from 'ra-core'

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      sort: field,
      order: order,
      page: (page - 1),
      size: perPage,
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => {
      return {
        data: json.items,
        total: parseInt(json.total, 10)
      }
    })
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),
  /**
   * TODO: Tip: Why does <ReferenceInput> use the dataProvider.getMany() method with a single value [id]
   * instead of dataProvider.getOne() to fetch the record for the current value? Because when
   * there are many <ReferenceInput> for the same resource in a form (for instance when inside an <ArrayInput>),
   * react-admin aggregates the calls to dataProvider.getMany() into a single one with [id1, id2, ...)].
   * This speeds up the UI and avoids hitting the API too much.
   * @param resource
   * @param params
   * @returns {Promise<{data: *}>}
   */
  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => {
      return { data: json.items }
    })
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [ params.target ]: params.id,
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: parseInt(
          headers
            .get('x-total-count')
            .split('/')
            .pop(),
          10
        ),
      }
    })
  },

  update: (resource, params) => {
    return updateData(httpClient, apiUrl, resource, params)
  },

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
});

const performUpdate = (httpClient, apiUrl, resource, params) => {
  return httpClient(`${apiUrl}/${resource}/${params.id}`, {
    method: 'PUT',
    body: JSON.stringify(params.data)
  }).then(({ json }) => ({ data: json }))
}

const updateData = async (httpClient, apiUrl, resource, params) => {
  if (resource === 'candidates'  || params?.data?.resumeFile || params?.data?.synthesisFile) {
    const resumeFile = params.data.resumeFile?.rawFile
    const synthesisFile = params.data.synthesisFile?.rawFile
    delete params.data.resumeFile
    delete params.data.synthesisFile

    const form = new FormData()
    form.append('candidate', JSON.stringify(params.data))
    if (resumeFile) {
      form.append('resumeFile', resumeFile)
    }
    if (synthesisFile) {
      form.append('synthesisFile', synthesisFile)
    }

    const headers = new Headers()
    headers.set('Accept', 'application/json')
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      headers: headers,
      method: 'PUT',
      body: form,
    }).then(({ json }) => ({ data: json }))
  } else {
    return performUpdate(httpClient, apiUrl, resource, params)
  }
}
