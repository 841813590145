import React from 'react'
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    Filter,
    ListBase,
    NumberField,
    Pagination,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    Title, useDataProvider,
} from 'react-admin'
import {Box, Button, Card} from "@material-ui/core";
import {CallbackCandidateList} from "./CallbackCandidateList";
import {Link} from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import useNotify from "ra-core/lib/sideEffect/useNotify";
import {DATA_POOL_INTERVAL} from "../../constants";

const PostFilter = props => {
    return (
        <Box py={'1em'}>
            <Filter {...props}>
                <TextInput label='Nom' source='lastName' alwaysOn/>
                <TextInput label='Prénom' source='firstName' alwaysOn/>
                <TextInput label='Localité' source='locality' alwaysOn/>
                <TextInput label='Département' source='department' alwaysOn/>
                <TextInput label='Téléphone' source='phoneNumber' alwaysOn/>
                <ReferenceInput label="Pôle" source="jobTypeId" reference="jobTypes" perPage={100} allowEmpty alwaysOn>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <BooleanInput label="A l'écoute" source="isLooking" alwaysOn/>
                <BooleanInput label="Ne pas contacter" source="avoidCall" alwaysOn/>
            </Filter>
            <Link to={{pathname: `/candidates/create`}}  style={{textDecoration: 'none'}}>
                <Button variant="containedPrimary" startIcon={<AddIcon />}>Ajouter</Button>
            </Link>
        </Box>
    )
}

const candidateRowStyle = (row, index) => {
    let color = '#FFFFFF'
    if (row.avoidCall) {
        color = '#a94442'
    } else if (row.isLooking) {
        color = '#ADDFFF'
    }
    return {
        backgroundColor: color
    }
}

const CustomList = ({ children, title, ...props }) => (
    <ListBase {...props}>
        <Title title={title}/>
        <PostFilter {...props} />
        <Card>
            {children}
            <Pagination/>
        </Card>
    </ListBase>
);

export const CandidateList = props => {
    const [candidates, setCandidates] = React.useState([])
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchItems = React.useCallback(() => {
        dataProvider.getOne('candidates', {id: 'callback'})
            .then((response) => {
                setCandidates(response.data)
            } )
            .catch((error) => {
                notify(error.message)
            })
    }, [dataProvider, notify])


    React.useEffect(() => {
        const interval = setInterval(() => {
            fetchItems()
        }, DATA_POOL_INTERVAL);
        return () => clearInterval(interval);
    }, [fetchItems]);

    return (
        <CustomList {...props}  >
            <CallbackCandidateList candidates={candidates} />
            <Datagrid rowClick="edit" rowStyle={candidateRowStyle}>
                <TextField source="id"/>
                <NumberField source="department"/>
                <TextField source="locality"/>
                <TextField source="company"/>
                <TextField source="officePhoneNumber"/>
                <TextField source="firstName"/>
                <TextField source="lastName"/>
                <BooleanField source="contacted"/>
                <TextField source="phoneNumber"/>
                <TextField source="lookingReason"/>
                <DateField source="calledOn"/>
                <BooleanField source="emailSent"/>
                <BooleanField source="resumeReceived"/>
            </Datagrid>
        </CustomList>
    )
}
