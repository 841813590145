import {Box, Card, CardHeader, Typography} from "@material-ui/core";
import UserIcon from "@material-ui/icons/WarningRounded";
import {Link} from "react-router-dom";
import React from "react";

const cardStyle = {
    margin: '0.5em',
    verticalAlign: 'top'
}

export const CallbackCandidateList = ({candidates}) => {
    if (!Array.isArray(candidates) || !candidates.length) {
        return null
    }
    return (
        <Box p={'1em'}>
            <Box px={'0.5em'} pb={'0.5em'} display={'flex'} flexDirection={'row'} alignContent={'center'}>
                <UserIcon style={{marginRight: '5px'}}/>
                <Typography variant="body1" style={{fontWeight: 700}}>Candidats à rappeler</Typography>
            </Box>

            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-end'}}>
                {candidates.map((candidate, index) =>
                    <Link to={{pathname: `/candidates/${candidate.id}`}} key={index} style={{textDecoration: 'none'}}>
                        <Card key={candidate.id} style={cardStyle}>
                            <CardHeader
                                title={<Typography>{candidate.firstName} {candidate.lastName} </Typography>}
                                subheader={<Typography>{candidate.phoneNumber}</Typography>}
                            />
                        </Card>
                    </Link>
                )}
            </div>
        </Box>
    )
}
