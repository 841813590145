import React from 'react'
import { ChipField } from 'react-admin'

const ChipArrayField = ({ source, record = {}, choices = {} }) => {
  if (!Array.isArray(record[source])) {
    return null
  }

  let recordsObject = {};
  if (Array.isArray(choices)) {
    for (const choice of choices) {
      recordsObject[choice.id] = choice.name
    }
  } else {
    recordsObject = choices
  }

  return (
    record[source].map((e, index) => <ChipField key={index} source={e} record={recordsObject}/>)
  )
}

export default ChipArrayField
