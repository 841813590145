import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  required
} from 'react-admin'

import { parse } from "query-string";

export const CommentCreate = props => {
  const { candidateId } = parse(props.location.search);
  const redirect = `/candidates/${candidateId}`;
  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ candidateId }} redirect={redirect}>
        <TextInput label="Votre message" source="content" validate={required()} multiline fullWidth/>
      </SimpleForm>
    </Create>
  );
};
