import React from 'react'
import { Edit, SimpleForm, DateInput, TextInput, SelectArrayInput, SelectInput } from 'react-admin'
import { userRoles } from '../../constants'


export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="email" />
      <TextInput disabled source="username" />
      <DateInput disabled source="createdOn" />
      <SelectInput source="gender" choices={[
        { id: 'M', name: 'Masculin' },
        { id: 'F', name: 'Féminin' },
      ]} />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="phoneNumber" />
      <SelectArrayInput label="Rôles" source="roles" choices={userRoles} />
    </SimpleForm>
  </Edit>
);
